@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap");

html,
body {
  margin: 0;
  font-family: "Lato", serif;
}
button {
  font-family: "Lato", sans-serif;
}
span {
  font-family: "Lato", sans-serif;
}
a {
  font-family: "Lato", sans-serif;
}
* {
  box-sizing: border-box;
}

.pointer:hover {
  cursor: pointer;
}

/* poppins-devanagari-400-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/poppins-devanagari-400-normal.87c72f23.woff2) format('woff2'), url(/_next/static/media/poppins-devanagari-400-normal.f2c29400.woff) format('woff');
  unicode-range: U+0900-097F,U+1CD0-1CF9,U+200C-200D,U+20A8,U+20B9,U+25CC,U+A830-A839,U+A8E0-A8FF;
}

/* poppins-latin-ext-400-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/poppins-latin-ext-400-normal.591327bf.woff2) format('woff2'), url(/_next/static/media/poppins-latin-ext-400-normal.687cae43.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* poppins-latin-400-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/poppins-latin-400-normal.916d3686.woff2) format('woff2'), url(/_next/static/media/poppins-latin-400-normal.74033fb9.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
